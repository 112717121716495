import css from 'styled-jsx/css';

const styles = css.global`/* @styled-jsx=global */
@import "./styles/global-variables";

@font-face {
  font-display: swap;
  font-family: "M PLUS Rounded 1c";
  font-weight: normal;
  src: url("/static/font/MPLUSRounded1c-Regular.woff");
}

@font-face {
  font-display: swap;
  font-family: "M PLUS Rounded 1c";
  font-weight: 500;
  src: url("/static/font/MPLUSRounded1c-Medium.woff");
}

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-weight: bold;
  src: url("/static/font/OpenSans-Bold.woff");
}

@font-face {
  font-display: swap;
  font-family: "Noto Sans JP";
  font-weight: normal;
  src: url("/static/font/NotoSansJP-Regular.otf");
}

@font-face {
  font-display: swap;
  font-family: "Noto Sans JP";
  font-weight: 500;
  src: url("/static/font/NotoSansJP-Medium.otf");
}

@font-face {
  font-display: swap;
  font-family: "Noto Sans JP";
  font-weight: bold;
  src: url("/static/font/NotoSansJP-Bold.otf");
}

@font-face {
  font-display: swap;
  font-family: "Noto Sans JP";
  font-weight: 400;
  src: url("/static/font/NotoSansJP-light.otf");
}

@font-face {
  font-display: swap;
  font-family: "Noto Serif JP";
  font-weight: normal;
  src: url("/static/font/NotoSerifJP-Regular.otf");
}

@font-face {
  font-display: swap;
  font-family: "Noto Serif JP";
  font-weight: 500;
  src: url("/static/font/NotoSerifJP-Medium.otf");
}

@font-face {
  font-display: swap;
  font-family: "Noto Serif JP";
  font-weight: 600;
  src: url("/static/font/NotoSerifJP-SemiBold.otf");
}

@font-face {
  font-display: swap;
  font-family: "Caveat-Bold";
  src: url("/static/font/Caveat-Bold.ttf");
}

@font-face {
  font-display: swap;
  font-family: "Caveat-Medium";
  src: url("/static/font/Caveat-Medium.ttf");
}

@font-face {
  font-display: swap;
  font-family: "Caveat-Regular";
  src: url("/static/font/Caveat-Regular.ttf");
}

@font-face {
  font-display: swap;
  font-family: "Caveat-Bold";
  src: url("/static/font/Caveat-SemiBold.ttf");
}

@font-face {
  font-display: swap;
  font-family: "SourceSansPro-Regular";
  src: url("/static/font/SourceSansPro-Regular.ttf");
}

* {
  outline: none;
}

body,
html {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.main-container {
  height: 100%;
  position: relative;

  @media all and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    overflow-x: hidden;
  }
}

.lazyload-wrapper {
  width: 100%;
}

.login-text {
  padding: 25px 25px 10px;
  font-family: "M PLUS Rounded 1c", sans-serif;
  color: $purple-dark;
  font-size: 15px;
  font-weight: 500;
  margin: 10px 0 0 0;

  .popup-login-cta {
    border: none;
    background: none;
    font-size: 14px;
    text-decoration: underline;
    color: $link-blue;
  }
}

.full-width {
  width: 100%;
}

.spacer-fat {
  background-color: $purple-darker;
  height: 1px;
  width: 100%;
  float: left;
  margin: 40px 0;
}

.spacer-thin {
  background-color: $purple;
  height: 1px;
  width: 100%;
}

.colspace .submit_button a {
  color: #fff;
}

.btn-round {
  border-radius: 18px;
}

.btn-empty {
  background-color: white;
  border: 1px solid $purple-dark;
  color: $purple-dark;
  padding: 10px 50px;
}

.cta {
  background-color: $purple-dark;
  border: 0;
  box-sizing: border-box;
  color: white;
  display: block;
  position: relative;
  word-break: keep-all;
}

/* util */

.pull-right {
  float: right;
}

.clearfix {

  &::after,
  &::before {
    clear: both;
    content: "";
    display: table;
  }
}

.clear {
  clear: both;
}

/* bootstrap overrides */

.container {
  box-sizing: border-box;
  max-width: 970px;
  min-height: 1px;
}

.content-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 58px;

  @media (min-width: $breakpoint-min) {
    margin-bottom: 5rem;
    margin-top: 100px;
  }
  &.productListingPage {
    border: none;
    margin: 100px auto 0;
    max-width: 1180px;
    
    @media (max-width: $breakpoint-dt-min) {
      width: 90%;
    }

    .breadcum-plp {
      padding: 30px 0;

      a.breadcrumb-anchor,
      span.breadcrumb-span {
        color: $text-darker-black;
        font-size: 1rem;
        font-family: "Noto Sans JP";
        font-weight: normal;
        &:hover {
          text-decoration: none;
        }
      }
    }

    .plpTabContainer {
      h1 {
        font-size: 18px;
        color: $text-darker-black;
        font-family: "Noto Sans JP";
        font-weight: 500;
      }
    }

    .buy-now-button {
      @media (max-width: $breakpoint-ipad-max) {
        margin-top: 0;
      }
    .buy-now-cta {
      padding: 0 0 40px !important;
      display: flex;
      justify-content: center;
      font-family: 'Noto Sans JP';
      font-weight: 500;
    .buttoncover {
      font-family: 'Noto Sans JP';
      font-weight: 500;
    }
    .buy-now-ps-div-ref {
      background-color: $purple-darker;
      border: 1px solid $purple-darker;
      color: #fff !important;
      border-radius: 32px;
      width: 200px;
      margin: auto;
      padding: 8px 10px;
      font-size: 16px;
      font-family: 'Noto Sans JP';
      font-weight: 500;
      position: relative;

      &::after {
        content: ">";
        font-size: 18px;
        color: #fff;
        position: absolute;
        right: 10px;
        font-weight: 600;
    }
    }
  }
}

    .productsLineUp-card-text {
    .secnt-size {
      display: flex;
      justify-content: center;

      @media (max-width: $breakpoint-ipad-max) {
        align-items: center;
        max-height: 50px;
        height: 50px;
      }

      img {
        width: 100px;

        @media (max-width: $breakpoint-ipad-max) {
          width: 80px;
          height: 24px;
        }        
      }

      .refreshing-scent, .unscented {
        margin: auto 12px;
        padding: 5px 20px;
        background-color: #785ea2; // Accessibility color contrast issue
        border: 1px solid #896BB8;
        border-radius: 24px;
        color: #fff;
        font-size: 14px;
        font-family: "Noto Sans JP";
        font-weight: 500;

        @media (max-width: $breakpoint-ipad-max) {
          font-size: 10px;
          padding: 5px 10px;
          border-radius: 10px;
        }
      }

      .unscented {
        background-color: darken($light-blue-bg, 20%) !important;
        border: 1px solid darken($light-blue-bg, 20%) !important;
      }
    }

    .pack-size-container {
      font-size: 18px;
      color: #64369F;
      font-family: 'Noto Sans JP';
      font-weight: 500;
      padding: 30px 0;

      @media (max-width: $breakpoint-ipad-max) {
        font-size: 14px;
      }
    }
  }
}
}

.Homepage,
.productListingPage,
.PremiumPage,
.BasicPage,
.BasicPagePDP,
.BasicPagesPDP,
.AbsorbencyPage,
.PhilosophyPage,
.ProductDiagnosisPage {
  margin-top: 0;

  .productSection {
    .productsLineUp {
      .productsLineUp-card {
        .productsLineUp-head {
          background: #efefef;
          font-size: 20px;
          font-family: "Noto Sans JP";
          font-weight: normal;
          padding: 20px 0;
          color: $purple;
          text-align: left;

          @media (max-width: $breakpoint-max) {
            font-size: 18px;
          }
        }

        h4 {
          color: $purple;
        }

        .ps-widget {
          padding-left: 50px;
          padding-top: 4px;
          padding-bottom: 4px;
          padding-right: 27px;
          background: url("https://cdn.pricespider.com/1/1766/5d77e7afc333fc0019bbd2ac/images/cart.png") no-repeat;
          background-image: url("https://cdn.pricespider.com/1/1766/5d77e7afc333fc0019bbd2ac/images/cart.png");
          background-position-x: center;
          background-position-y: center;
          background-size: 100%;
          background-attachment: initial;
          background-origin: initial;
          background-clip: initial;
          background-color: initial;
          background-position: center;
          background-size: 100%;
          color: #fff;
          line-height: 18px;
          height: 60px;
          width: 192px;

          @media (max-width: $breakpoint-max) {
            padding-left: 36px;
            padding-right: 19px;
            line-height: 16px;
            width: 150px;
            height: 48px;
          }

          small {
            line-height: 24px !important;

            @media (max-width: $breakpoint-max) {
              line-height: 22px !important;
            }
          }
        }
      }
    }
  }

  @media (max-width: $breakpoint-max) {
    margin-top: 0;
  }
}

.PremiumPage {
  .productSection {
    .productsLineUp {
      .productsLineUp-card {
        .productsLineUp-head {
          color: $gold !important;
        }

        h4 {
          color: $gold !important;
        }
      }
    }
  }
}

.text-center {
  text-align: center;
}

/*Dialog Popup*/

.DialogBtn {
  background-color: #592c82;
  border: 0;
  border-radius: 25px;
  color: white;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  padding: 8px 40px;
  position: relative;

  a {
    color: #fff;
    text-decoration: none;
  }
}

.DialogBtn::after {
  border-bottom: 7px solid transparent;
  border-left: 12px solid white;
  border-right: 7px solid transparent;
  border-top: 7px solid transparent;
  content: "";
  font-size: 0;
  position: absolute;
  right: 4px;
  top: 15px;
}

.Loader-overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  padding-top: 110px;
  overflow-y: auto;
  z-index: 40;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);

  .Dialog-content {
    z-index: 1500;
    width: 45vw;
    background: rgb(255, 255, 255);
    padding: 20px 15px;
    min-height: 15vh;
    margin: auto;
    position: relative;
    height: 35vh;
    border-radius: 10px;
    top: 20%;

    .Dialog-text {
      text-align: center;
      margin-top: 30px;
    }

    @media only screen and (max-width: 767px) {
      width: 75%;

      .Dialog-text {
        font-size: 12px;
      }
    }

    .closespan {
      font-size: 30px;
    }

    .closeBtn {
      right: 10px;
      top: 10px;
      background: none;
      border: none;
      position: absolute;
    }
  }
}

.Loader-overlay.headerHide {
  z-index: 101;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0;
}

.Dialog-overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 110px;
  overflow-y: auto;
  z-index: 40;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);

  @media only screen and (max-width: 767px) {
    padding-top: unset;
  }

  .Dialog-content {
    z-index: 1500;
    width: 45vw;
    background: rgb(255, 255, 255);
    margin: auto;
    position: relative;
    height: auto;
    border-radius: 10px;
    top: 15%;

    .Dialog-text {
      text-align: center;
      margin-top: 30px;
      padding: 20px 15px;

      .successMsgInfo {
        color: $purple;
      }

      .failMsgInfo {
        color: red;
      }
    }

    @media only screen and (max-width: 767px) {
      width: 75%;

      .Dialog-text {
        text-align: center;
        font-size: 12px;
        padding: 45px 15px 20px 15px;
      }
    }

    .closespan {
      font-size: 30px;
    }

    .closeBtn {
      right: 10px;
      top: 10px;
      background: none;
      border: none;
      position: absolute;
    }
  }
}

/* cross-component overrides */

@media (min-width: $breakpoint-min) {
  .promo-banners {
    .row {
      margin-left: -30px;
      margin-right: 0;
    }
  }
}

.static-header {
  &+.article-list {
    &.container {
      margin-top: 0;
    }
  }
}

.id-binding-main-page,
.id-bind-lohaco {
  width: 940px;
  margin: 120px auto 5rem;

  @media only screen and (max-width: 991px) {
    width: 100% !important;
    margin: 20px auto;
    overflow-x: hidden;
  }

  @media only screen and (max-width: 776px) {
    width: 100% !important;
    margin: 20px auto;
    overflow-x: hidden;
  }
}

// .id-bind-lohaco {
//   width: 940px;
//   margin: 120px auto 5rem;
// }

/// Image CArousel /////////////////////////////////////////@at-root

$ig-small-screen: 768px !default;
$ig-xsmall-screen: 480px !default;
$ig-white: #fff !default;
$ig-black: #000 !default;
$ig-blue: #337ab7 !default;
$ig-background-black: rgba(0, 0, 0, 0.4) !default;
$ig-transparent: rgba(0, 0, 0, 0) !default;

@mixin vendor-prefix($name, $value) {
  @each $vendor in ("-webkit-", "-moz-", "-ms-", "-o-", "") {
    #{$vendor}#{$name}: #{$value};
  }
}

// SVG ICON STYLES
.image-gallery-icon {
  color: $ig-white;
  transition: all 0.2s ease-out;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  filter: drop-shadow(0 2px 2px lighten($ig-black, 10%));

  @media (min-width: $ig-small-screen) {

    // Don't hover on screens smaller than small
    &:hover {
      color: $ig-blue;

      .image-gallery-svg {
        transform: scale(1.1);
      }
    }
  }

  &:focus {
    // a11y support
    outline: 2px solid $ig-blue;
  }
}

.image-gallery-using-mouse {
  .image-gallery-icon {
    &:focus {
      outline: none;
    }
  }
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px;

  .image-gallery-svg {
    height: 36px;
    width: 36px;
  }

  @media (max-width: $ig-small-screen) {
    padding: 15px;

    .image-gallery-svg {
      height: 24px;
      width: 24px;
    }
  }

  @media (max-width: $ig-xsmall-screen) {
    padding: 10px;

    .image-gallery-svg {
      height: 16px;
      width: 16px;
    }
  }
}

.image-gallery-fullscreen-button {
  right: 0;
}

.image-gallery-play-button {
  left: 0;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%);

  .image-gallery-svg {
    height: 120px;
    width: 60px;
  }

  @media (max-width: $ig-small-screen) {
    .image-gallery-svg {
      height: 72px;
      width: 36px;
    }
  }

  @media (max-width: $ig-xsmall-screen) {
    .image-gallery-svg {
      height: 48px;
      width: 24px;
    }
  }

  &[disabled] {
    cursor: disabled;
    opacity: 0.6;
    pointer-events: none;
  }
}

.image-gallery-left-nav {
  left: 0;
}

.image-gallery-right-nav {
  right: 0;
}

// End of Icon styles

.image-gallery {
  @include vendor-prefix("user-select", none);
  -webkit-tap-highlight-color: $ig-transparent;
  position: relative;

  &.fullscreen-modal {
    background: $ig-black;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5;

    .image-gallery-content {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0;

  &.fullscreen {
    background: $ig-black;
  }

  .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 80px); // 80 px for the thumbnail space
  }

  &.left,
  &.right {
    .image-gallery-slide .image-gallery-image {
      max-height: 100vh;
    }
  }
}

.image-gallery-slide-wrapper {
  position: relative;

  &.left,
  &.right {
    display: inline-block;
    width: calc(100% - 246px); // 100px + 10px for margin

    @media (max-width: $ig-small-screen) {
      width: calc(100%); // 81px + 6px for margin
    }
  }

  &.image-gallery-rtl {
    direction: rtl;
  }
}

.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
}

.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &.center {
    position: relative;
  }

  .image-gallery-image {
    width: 100%;
    object-fit: contain;
  }

  .image-gallery-description {
    background: $ig-background-black;
    bottom: 70px;
    color: $ig-white;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal;

    @media (max-width: $ig-small-screen) {
      bottom: 45px;
      font-size: 0.8em;
      padding: 8px 15px;
    }
  }
}

.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4;

  .image-gallery-bullets-container {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .image-gallery-bullet {
    appearance: none;
    background-color: transparent;
    border: 1px solid $ig-white;
    border-radius: 50%;
    box-shadow: 0 1px 0 lighten($ig-black, 10%);
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    outline: none;
    padding: 5px;
    transition: background 0.2s ease-out;

    @media (max-width: $ig-small-screen) {
      margin: 0 3px;
      padding: 3px;
    }

    @media (max-width: $ig-xsmall-screen) {
      padding: 2.7px;
    }

    &:focus,
    &:hover {
      background: $ig-blue;
      transform: scale(1.1);
    }

    &.active {
      background: $ig-white;
    }
  }
}

.image-gallery-thumbnails-wrapper {
  position: relative;

  &.thumbnails-wrapper-rtl {
    direction: rtl;
  }

  &.left,
  &.right {
    display: inline-block;
    vertical-align: top;
    width: 223px;

    @media (max-width: $ig-small-screen) {
      width: 81px; // 75px + 6px for border
    }

    .image-gallery-thumbnails {
      height: 100%;
      width: 100%;
      left: 0;
      padding: 0;
      position: absolute;
      top: 0;

      .image-gallery-thumbnail {
        display: block;
        margin-right: 0;
        padding: 0;

        +.image-gallery-thumbnail {
          margin-left: 0;
          margin-top: 2px;
        }
      }
    }
  }

  &.left,
  &.right {
    margin: 0 10px;

    @media (max-width: $ig-small-screen) {
      margin: 0 3px;
    }
  }
}

.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0;

  @media (max-width: $ig-small-screen) {
    padding: 3px 0;
  }

  .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    transition: transform 0.45s ease-out;
    white-space: nowrap;
  }
}

.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border 0.3s ease-out;
  width: 223px;
  background: transparent;
  padding: 0;

  @media (max-width: $ig-small-screen) {
    border: 3px solid transparent;
    width: 81px;
  }

  +.image-gallery-thumbnail {
    margin-left: 2px;
  }

  .image-gallery-thumbnail-inner {
    position: relative;
  }

  .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0;
  }

  &.active,
  &:hover,
  &:focus {
    outline: none;
    border: 4px solid $pink;

    @media (max-width: $ig-small-screen) {
      border: 3px solid $ig-blue;
    }
  }
}

.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: white;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: 1px 1px 0 black;
  transform: translateY(-50%);
  white-space: normal;
  width: 100%;

  @media (max-width: $ig-small-screen) {
    font-size: 0.8em;
    line-height: 0.8em;
  }
}

.image-gallery-index {
  background: $ig-background-black;
  color: $ig-white;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;

  @media (max-width: $ig-small-screen) {
    font-size: 0.8em;
    padding: 5px 10px;
  }
}

.left-arrow,
.right-arrow {
  border: 1px solid $purple;
  border-radius: 50%;
  color: $purple;
  cursor: pointer;
  height: 50px;
  padding: 10px 20px;
  position: absolute;
  top: 35%;
  width: 50px;
  z-index: 1;

  &::before {
    border-bottom: 1px solid $purple-dark;
    border-left: 1px solid $purple-dark;
    content: "";
    font-size: 0;
    height: 12px;
    left: 55%;
    position: absolute;
    top: 45%;
    transform: translate(-50%, -50%);
    width: 12px;

    @media (max-width: $breakpoint-max) {
      border-width: 2px;
    }
  }

  @media (max-width: $breakpoint-max) {
    border-width: 2px;
    padding: 5px 10px;
    top: 40%;
  }
}

.right-arrow {
  right: 11%;
  transform: rotate(225deg);

  @media (max-width: $breakpoint-max) {
    border-width: 2px;
    right: 1%;
    transform: rotate(225deg) scale(0.5);
  }
}

.left-arrow {
  left: 11%;
  transform: rotate(45deg);

  @media (max-width: $breakpoint-max) {
    left: 1%;
    transform: rotate(45deg) scale(0.5);
  }
}

/* Video Feedback Css */
.videofeedback-campaign {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.videofeedback-container {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 500;
  width: 940px;
  margin: 0 auto;
  color: $text-article;

  @media only screen and (max-width: 767px) {
    width: 100% !important;
    padding: 20px;
  }

  .VF-Headersection {
    @media only screen and (max-width: 767px) {
      font-size: 20px;
      line-height: 30px;
    }
  }
}

.videofeedback-campaign {
  width: 100%;
  position: relative;

  .VideoComments {
    max-width: 100%;
    margin: auto;

    @media only screen and (max-width: 767px) {
      width: 94% !important;
    }
  }

  .HeadingContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $purple;
    padding: 10px 25px;

    @media only screen and (max-width: 767px) {
      padding: 20px;
      flex-direction: column;
    }

    .Heading {
      font-size: 29px;
      color: #fff;

      @media only screen and (max-width: 767px) {
        font-size: 19px;
        text-align: center;
      }
    }

    button {
      background: #fff;
      color: $purple;
      border: none;
      outline: none;
      font-weight: bold;
      border-radius: 10px;
      font-weight: normal;
      padding: 5px 29px;

      @media only screen and (max-width: 767px) {
        margin: 20px;
        font-size: 20px;
        padding: 5px 20px;
      }
    }
  }

  .contentConatiner {
    margin: 10px 0;
    padding: 20px 30px;
    background: $purple-light;
    position: relative;

    .Description {
      color: #592c82;
      font-size: 32px;
      line-height: 42px;
      font-weight: bold;
      text-align: center;

      @media only screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 30px;
      }
    }

    .hidden {
      display: none;
    }

    .LoginAlert {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.6);
      display: flex;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: 767px) {
        align-items: flex-start;
      }

      span {
        background: #898c8e;
        color: #fff;
        padding: 12px 25px;

        @media only screen and (max-width: 767px) {
          padding: 50px 30px;
          margin-top: 350px;
          text-align: center;
          font-size: 18px;
        }
      }
    }

    .formFlex {
      display: flex;

      @media only screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    .nameLabel,
    .ageLabel {
      display: flex;
      flex-direction: column;
      margin-right: 20px;

      input {
        padding: 0.5em 3.5em 0.5em 1em;
      }

      select {
        background-color: white;
        border: none;
        display: inline-block;
        font: inherit;
        line-height: 1.5em;
        padding: 0.5em 3.5em 0.5em 1em;
        margin: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: linear-gradient(45deg, transparent 50%, white 50%),
          linear-gradient(135deg, white 50%, transparent 50%),
          linear-gradient(to right, skyblue, skyblue);
        background-position: calc(100% - 20px) calc(1em + 2px),
          calc(100% - 15px) calc(1em + 2px), 100% 0;
        background-size: 6px 10px, 6px 10px, 2.5em 2.5em;
        background-repeat: no-repeat;
      }

      select:focus {
        background-image: linear-gradient(45deg, white 50%, transparent 50%),
          linear-gradient(135deg, transparent 50%, white 50%),
          linear-gradient(to right, gray, gray);
        background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
          100% 0;
        background-size: 6px 10px, 5px 10px, 2.5em 2.5em;
        background-repeat: no-repeat;
        border-color: grey;
        outline: 0;
      }

      @media only screen and (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }

    .nameLabel label,
    .ageLabel label,
    .commentsLabel label {
      font-weight: bold;
    }

    .nameLabel input,
    .ageLabel select,
    .commentsLabel textarea {
      border: none;
    }

    .ageLabel select option {
      border-bottom: 1px solid red;
    }

    @media only screen and (max-width: $breakpoint-max) {
      textarea {
        min-height: 200px;
        height: 250px;
        overflow-y: scroll;
        resize: none;
        width: 100%;
      }

      textarea::-webkit-scrollbar {
        width: 10px;
        background-color: #f5f5f5;
      }

      textarea::-webkit-scrollbar-thumb {
        background-color: #8347ad;
        border-radius: 5px;
      }

      textarea::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #e5d3ef;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .commentsLabel {
      display: flex;
      flex-direction: column;
      margin: 15px 0;
    }

    .submitSection {
      display: flex;

      @media only screen and (max-width: 767px) {
        flex-direction: column;
      }

      button {
        background: $purple;
        color: #fff;
        font-size: 32px;
        line-height: 42px;
        width: 45%;
        border-radius: 20px;

        @media only screen and (max-width: 767px) {
          width: 100%;
          border-radius: 20px;
          font-size: 24px;
          line-height: 42px;
          margin: 0px 0 10px;
        }
      }

      .conditionApply {
        width: 55%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media only screen and (max-width: 767px) {
          width: 100%;
        }

        p {
          font-size: 10px;
          margin: 0 0 0 20px;
        }
      }
    }
  }
}

/*Comments Section*/
.VC-left-container {
  @media only screen and (max-width: 768px) {
    clear: both;
    width: 100% !important;
  }

  .VC-comments-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .VC-Evencomments {
    width: 48%;
    margin-bottom: 35px;
    background: #dccaea;
    position: relative;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    .topContent {
      display: flex;
      margin: 4px 4px 1px;
      padding: 3px 3px 7px;
      background: #fff;

      .VC-comments-name {
        width: 52%;
        margin-right: 3%;

        label {
          margin-bottom: 0;
          padding: 0 5px;
          color: $purple-dark;
        }

        .nickName {
          padding: 0 5px;
        }
      }

      .VC-comments-age {
        width: 45%;

        label {
          margin-bottom: 0;
          padding: 0 5px;
          color: $purple-dark;
        }

        .ageCostomer {
          padding: 0 5px;
        }
      }
    }

    .VC-comments-body {
      padding: 7px;
      border-top: none;
      height: 300px;
      overflow: hidden;
      margin: 0 4px 7px;
      background: #fff;
      padding: 0 10px;

      @media only screen and (max-width: 767px) {
        height: 270px;
      }

      .commentsCust {
        line-height: 28px;
      }
    }
  }

  .VC-Evencomments:nth-child(2),
  .VC-Evencomments:nth-child(3),
  .VC-Evencomments:nth-child(6) {
    background: #75038f;
    background: -moz-linear-gradient(-45deg,
        #75038f 0%,
        #8311b3 21%,
        #8637de 44%,
        #8a49eb 58%,
        #c95bf8 82%);
    background: -webkit-linear-gradient(-45deg,
        #75038f 0%,
        #8311b3 21%,
        #8637de 44%,
        #8a49eb 58%,
        #c95bf8 82%);
    background: linear-gradient(135deg,
        #75038f 0%,
        #8311b3 21%,
        #8637de 44%,
        #8a49eb 58%,
        #c95bf8 82%);

    .topContent {
      background: transparent;
      margin: 7px 7px 5px;
      padding: 0;

      .VC-comments-age,
      .VC-comments-name {
        label {
          color: #eee5f4;
        }

        .nickName {
          background: #fff;
        }

        .ageCostomer {
          background: #fff;
        }
      }
    }

    .VC-comments-body {
      margin: 0 7px 7px;
      padding: 7px;
    }
  }

  .VC-Evencomments:nth-child(3) {
    @media only screen and (max-width: 767px) {
      background: #dccaea;
    }

    .topContent {
      @media only screen and (max-width: 767px) {
        margin: 4px 4px 1px;
        padding: 3px 3px 7px;
        background: #fff;
      }

      .VC-comments-name {
        @media only screen and (max-width: 767px) {
          width: 52%;
          margin-right: 3%;
        }

        label {
          @media only screen and (max-width: 767px) {
            margin-bottom: 0;
            padding: 0 5px;
            color: #575757;
          }
        }

        .nickName {
          @media only screen and (max-width: 767px) {
            padding: 0 5px;
          }
        }
      }

      .VC-comments-age {
        @media only screen and (max-width: 767px) {
          width: 45%;
        }

        label {
          @media only screen and (max-width: 767px) {
            margin-bottom: 0;
            padding: 0 5px;
            color: #575757;
          }
        }

        .ageCostomer {
          @media only screen and (max-width: 767px) {
            padding: 0 5px;
          }
        }
      }
    }

    .VC-comments-body {
      @media only screen and (max-width: 767px) {
        margin: 0 4px 7px;
        padding: 0 10px;
      }
    }
  }

  .VC-Evencomments:nth-child(2) {
    @media only screen and (max-width: 767px) {
      background: #770692;
      background: -moz-linear-gradient(top,
          #770692 0%,
          #8d45e9 50%,
          #770692 100%);
      background: -webkit-linear-gradient(top,
          #770692 0%,
          #8d45e9 50%,
          #770692 100%);
      background: linear-gradient(to bottom,
          #770692 0%,
          #8d45e9 50%,
          #770692 100%);
    }
  }

  .VC-Evencomments:nth-child(4) {
    @media only screen and (max-width: 767px) {
      background: #770692;
      background: -moz-linear-gradient(top,
          #770692 0%,
          #8d45e9 50%,
          #770692 100%);
      background: -webkit-linear-gradient(top,
          #770692 0%,
          #8d45e9 50%,
          #770692 100%);
      background: linear-gradient(to bottom,
          #770692 0%,
          #8d45e9 50%,
          #770692 100%);
    }

    .topContent {
      @media only screen and (max-width: 767px) {
        background: transparent;
        margin: 7px 7px 5px;
        padding: 0;
      }

      .VC-comments-age,
      .VC-comments-name {
        label {
          @media only screen and (max-width: 767px) {
            color: #fff;
          }
        }

        .nickName {
          @media only screen and (max-width: 767px) {
            background: #fff;
          }
        }

        .ageCostomer {
          @media only screen and (max-width: 767px) {
            background: #fff;
          }
        }
      }
    }

    .VC-comments-body {
      @media only screen and (max-width: 767px) {
        margin: 0 7px 7px;
        padding: 7px;
      }
    }
  }

  .paginate {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .paginationStyles {
      text-align: center;

      button {
        border: 1px #592c82 solid;
        color: #592c82;
        background: #fff;
        padding: 5px 13px;
        margin: 0 11px;

        @media only screen and (max-width: 414px) {
          padding: 3px 10px;
          margin: 0 8px;
        }

        @media only screen and (max-width: 375px) {
          padding: 0px 5px;
          margin: 0 5px;
        }
      }

      .StartButtonClass {
        border: none;
        background: none;
        padding: 0;
        margin: 10px;
        border-bottom: 1px #575757 solid;
        color: #575757;
      }

      p {
        display: inline;
        margin: 0 10px;
        background: #592c82;
        color: #fff;
        padding: 7px 15px;
        opacity: 0.3;

        @media only screen and (max-width: 414px) {
          padding: 5px 12px;
        }

        @media only screen and (max-width: 375px) {
          padding: 2px 7px;
          margin: 0 5px;
        }
      }

      .break {
        color: #592c82;
        background: none;
        opacity: 1;

        @media only screen and (max-width: 320px) {
          padding: 2px;
        }
      }

      p.active {
        opacity: 1;
      }
    }
  }
}

.VC-right-container {
  border: none !important;

  @media only screen and (max-width: 768px) {
    clear: both;
    width: 100% !important;
  }

  .HeadingContainerRight {
    background: #eee5f4;
    padding: 15px;
    color: #595757;
    margin-bottom: 20px;

    @media only screen and (max-width: 768px) {
      margin-top: 20px;
    }

    p {
      font-size: 15px;
    }

    .videop {
      margin-bottom: 0;
    }

    button {
      background: #7c4195;
      padding: 10px 15px;
      color: #fff;
      border-radius: 15px;
      outline: none;
      border: none;
      width: 80%;
      margin: 10px 0 25px;

      @media only screen and (max-width: 768px) {
        max-width: 300px;
      }
    }

    button.register {
      margin: 10px 0 10px;
    }

    .VF-register-cta {
      button {
        background: #977076;
        background: -moz-linear-gradient(left,
            #977076 0%,
            #efd1c6 15%,
            #e4c2b8 20%,
            #e4c2b8 20%,
            #b68488 28%,
            #b68488 72%,
            #e4c2b8 80%,
            #efd1c6 87%,
            #977076 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(left,
            #977076 0%,
            #efd1c6 15%,
            #e4c2b8 20%,
            #e4c2b8 20%,
            #b68488 28%,
            #b68488 72%,
            #e4c2b8 80%,
            #efd1c6 87%,
            #977076 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,
            #977076 0%,
            #efd1c6 15%,
            #e4c2b8 20%,
            #e4c2b8 20%,
            #b68488 28%,
            #b68488 72%,
            #e4c2b8 80%,
            #efd1c6 87%,
            #977076 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }
    }
  }

  .VideoComments {
    .HeadingContainer {
      background: #eee5f4;
      padding: 15px;
      color: #595757;
      margin-bottom: 20px;

      button {
        background: #7c4195;
        padding: 10px 15px;
        color: #fff;
        border-radius: 15px;
        outline: none;
        border: none;
        width: 80%;
        margin: 20px 0 10px;

        @media only screen and (max-width: 768px) {
          max-width: 300px;
        }
      }
    }

    .contentConatiner {
      margin: 10px 0;
      padding: 20px;
      background: $purple-light;
      position: relative;

      .LoginAlert {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: 767px) {
          align-items: flex-start;
        }

        span {
          background: #898c8e;
          color: #fff;
          padding: 50px 30px;

          @media only screen and (max-width: 767px) {
            padding: 50px 30px;
            margin-top: 250px;
            text-align: center;
            font-size: 18px;
          }
        }
      }

      .formFlex {
        text-align: left;

        input,
        select {
          width: 100%;
          border: none;
        }

        .nameLabel,
        .ageLabel {
          margin-bottom: 15px;

          input {
            padding: 0.5em 3.5em 0.5em 1em;
          }

          select {
            background-color: white;
            border: none;
            display: inline-block;
            font: inherit;
            line-height: 1.5em;
            padding: 0.5em 3.5em 0.5em 1em;
            margin: 0;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-image: linear-gradient(45deg, transparent 50%, white 50%),
              linear-gradient(135deg, white 50%, transparent 50%),
              linear-gradient(to right, skyblue, skyblue);
            background-position: calc(100% - 20px) calc(1em + 2px),
              calc(100% - 15px) calc(1em + 2px), 100% 0;
            background-size: 6px 10px, 6px 10px, 2.5em 2.5em;
            background-repeat: no-repeat;
          }

          select:focus {
            background-image: linear-gradient(45deg, white 50%, transparent 50%),
              linear-gradient(135deg, transparent 50%, white 50%),
              linear-gradient(to right, gray, gray);
            background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
              100% 0;
            background-size: 6px 10px, 5px 10px, 2.5em 2.5em;
            background-repeat: no-repeat;
            border-color: grey;
            outline: 0;
          }
        }
      }

      .commentsLabel {
        text-align: left;
        margin-bottom: 15px;

        textarea {
          min-height: 200px;
          height: 250px;
          overflow-y: scroll;
          resize: none;
          width: 100%;
        }

        textarea::-webkit-scrollbar {
          width: 10px;
          background-color: #f5f5f5;
        }

        textarea::-webkit-scrollbar-thumb {
          background-color: #8347ad;
          border-radius: 5px;
        }

        textarea::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #e5d3ef;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      .submitSection {
        button {
          background: #7c4195;
          padding: 10px 15px;
          color: #fff;
          border-radius: 15px;
          outline: none;
          border: none;
          width: 80%;
          margin: 20px 0 10px;

          @media only screen and (max-width: 768px) {
            max-width: 300px;
          }
        }

        .conditionApply {
          @media only screen and (max-width: 767px) {
            width: 100%;
          }

          p {
            text-align: left;
            font-size: 10px;
            margin: 5px 0 0 20px;
          }
        }
      }
    }
  }
}

.videocomment-campaign {
  @media only screen and (max-width: 767px) {
    margin-bottom: 30px;
  }

  .VC-Headersection {
    .commencont {
      font-size: 18px !important;
      text-align: left;
      margin-bottom: 0;

      .feedbackvideos {
        background: none;
        outline: none;
        border: none;
        color: $link-blue;
      }
    }

    .headerFlex {
      display: flex;
      margin-bottom: 40px;

      @media only screen and (max-width: 768px) {
        flex-wrap: wrap;
      }

      .headerLeft {
        width: 620px;
        margin-right: 20px;

        @media only screen and (max-width: 768px) {
          width: 100%;
        }
      }

      .headerRight {
        align-items: flex-end;
        justify-content: flex-end;
        display: flex;
        cursor: pointer;

        @media only screen and (max-width: 768px) {
          align-items: center;
          justify-content: center;
          width: 100%;
        }

        .headerRighta {
          position: relative;
        }

        .headerRighta:after {
          background: url("https://images.ctfassets.net/8g0fievzqg8k/5iw8NbpyQ97E39YxQ8Goz4/ce02c9e6e8e280f91cfaec9f473c5a88/video-fb-bk-sp-blur-img.png");
          content: "";
          position: absolute;
          width: 100%;
          height: 35px;
          bottom: -18px;
          background-size: 100%;
          left: 0;

          @media only screen and (max-width: 768px) {
            bottom: -35px;
          }
        }

        p {
          background: url("https://images.ctfassets.net/8g0fievzqg8k/UHkd5Mjb6zAvXzGMvDGJu/cd868340db86cc0947b09cd62927dc8b/video-fb-bk-yoga-img.jpg");
          background-repeat: no-repeat;
          width: 300px;
          height: 89px;
          background-position: center bottom;
          background-size: 100%;
          color: #fff;
          text-align: left;
          padding-left: 95px;
          padding-top: 5px;

          @media only screen and (max-width: 768px) {
            margin-top: 20px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.VC-Evencomments::after {
  background: url("https://images.ctfassets.net/8g0fievzqg8k/5iw8NbpyQ97E39YxQ8Goz4/ce02c9e6e8e280f91cfaec9f473c5a88/video-fb-bk-sp-blur-img.png");
  content: "";
  position: absolute;
  width: 100%;
  height: 35px;
  bottom: -35px;
  background-size: 100%;
  left: 0;
}

.youtube-video-section {
  text-align: center;
  width: 100%;
}

.movieAdditionBtn {
  background: $pink;
  padding: 10px 50px;
  color: #fff;
  border-radius: 15px;
  outline: none;
  border: none;
  font-size: 24px;
  margin: 10px 0 60px;

  @media only screen and (max-width: 768px) {
    width: auto;
  }

  @media only screen and (max-width: 767px) {
    width: 80%;
    font-size: 20px;
    padding: 10px 15px;
  }
}

.video-youtube-part {
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: auto;
  }
}

.video-youtube-part-two {
  padding-top: 40px;
  padding-bottom: 30px;

  @media only screen and (max-width: 768px) {
    width: 96%;
    margin: auto;
  }
}

.youtubeVideo {
  background: #f5f5f5;
  width: 640px;
  margin: -40px auto 20px;
  padding: 20px;

  @media only screen and (max-width: $breakpoint-max) {
    width: 100%;
    margin: 34px auto 20px;

    iframe {
      width: 100% !important;
      height: 300px !important;
    }
  }
}

.disabled {
  cursor: not-allowed;
}

button:focus {
  outline: none;
}

.Textbox {
  height: 70px;
}

.Textbox::-webkit-scrollbar {
  width: 12px;
}

.Textbox::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 1px 0 6px #7c4195;
  border-radius: 10px;
}

.Textbox::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 8px 0 6px #7c4195;
}

.coupon-campaign {
  width: 100%;
  margin: 200px auto 80px;
  font-family: "M PLUS Rounded 1c", sans-serif;

  @media (max-width: $breakpoint-max) {
    width: 100%;
    margin: 80px auto 0px;
  }
}

.review-campaign-main-container .coupon-campaign {
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.review-campaign-main-container .breadcrumbs-container .container,
.review-campaign-main-container .breadcrumbs-container span,
.review-campaign-main-container .breadcrumbs-container a {
  color: #000000;
  font: normal normal normal 10px/15px Noto Sans JP;
  font-weight: bold;

  @media only screen and (min-width: 1366px) {
    font: normal normal normal 14px/24px Noto Sans JP;
    font-weight: bold;
  }
}

.review-campaign-main-container .breadcrumbs-container .container {
  margin-bottom: 20px;

  @media only screen and (min-width: 1366px) {
    margin-bottom: 10px;
    margin-top: -10px;
  }
}

.coupon-campaign-retailer {
  width: 100%;
  margin: 200px auto 80px;
  font-family: "M PLUS Rounded 1c", sans-serif;

  @media (max-width: $breakpoint-max) {
    width: 100%;
    margin: 80px auto 0px;
  }
}

.cashback-campaign {
  width: 100%;
  margin: 120px auto 80px;
  font-family: "M PLUS Rounded 1c", sans-serif;

  @media (max-width: $breakpoint-max) {
    width: 100%;
    margin: 58px auto 80px;
  }
}

.popup-container {
  .slick-dots {
    bottom: 50px !important;

    ul {
      li {
        cursor: pointer;

        div {
          margin: 0 5px !important;
        }
      }

      .slick-active {
        div {
          background: $purple-dark !important;
        }
      }
    }
  }

  @media (max-width: $breakpoint-max) {
    .slick-dots {
      bottom: 0px !important;

      ul {
        li {
          div {
            width: 30px !important;
            height: 6px !important;
          }
        }
      }
    }
  }
}

.valueSectionText {
  color: $purple;
  width: 100%;
  margin: 0 auto 68px;
  float: left;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  font-family: Noto Serif JP;
  font-weight: normal;

  @media (max-width: $breakpoint-max) {
    font-size: 24px;
    line-height: 35px;
    margin: 0 auto 58px;
    padding: 0 15px;
  }
}

.category1 {
  .gold-title {
    @media all and (max-width: $breakpoint-max) {
      .imageSection-text {
        margin-bottom: 8px;

        p {
          margin: 0;
        }
      }
    }
  }
}

.category2 {
  .gold-title {
    color: $gold !important;

    @media all and (max-width: $breakpoint-max) {
      .imageSection-text {
        margin-bottom: 8px;

        p {
          margin: 0;
        }
      }
    }
  }
}

.product-lineup-carousel {
  width: 970px;
  margin: 0 auto 42px;
  top: -70px;

  img {
    height: 220px;
    cursor: pointer;
  }

  .slick-list {
    height: 220px;
  }

  .slick-track {
    height: 220px;
  }

  .slick-arrow {
    width: 50px;
    position: absolute;
    margin-top: 8%;
    z-index: 9;
    font-size: 0;
    height: 50px;
    left: -3%;
    border: none;
    background: $purple-dark;

    &:before {
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff;
      content: "";
      font-size: 0;
      height: 25px;
      left: 18%;
      position: absolute;
      top: 25%;
      transform: rotate(45deg) scale(0.5);
      width: 25px;
    }
  }

  .slick-next {
    right: -3%;
    left: auto;
    margin-top: 8%;
    position: absolute;
    top: 0;

    &:before {
      transform: rotate(225deg) scale(0.5);
    }
  }

  .slick-slide {
    margin: 0px 6px;
  }

  @media (max-width: $breakpoint-max) {
    width: 100%;
    top: 0;
    margin: 0 auto;

    img {
      height: 100%;
    }

    .slick-list {
      height: auto;
    }

    .slick-track {
      height: auto;
    }

    .slick-slide {
      margin: 0;
    }

    .slick-dots {
      bottom: auto;

      ul {
        li {
          div {
            background-color: #fff !important;
          }
        }

        .slick-active {
          div {
            background-color: $purple-dark;
          }
        }
      }
    }
  }
}

.imageSection-wrapper-sp {
  position: relative;
  width: 100%;
  float: left;
  margin: 0 auto;

  .imageSection-img {
    margin-top: 120px;
  }

  .imageSection-text {
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    margin: 0 auto;
    padding: 0 10px;
    color: $purple;
    font-family: "Noto Serif JP";
    font-weight: normal;

    .philosophyHeading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      display: block;
    }

    .philosophySubHeading {
      font-size: 20px;
    }

    .philosophyText {
      font-size: 17px;
    }
  }

  .imageSection-cta {
    width: 220px;
    margin: 0 auto;
    text-align: center;

    a {
      text-align: center;
      margin: 10px auto;
      color: #fff;
      font-size: 20px;
      padding: 4px 20px;
      border-radius: 20px;
      float: left;
      width: 100%;
      font-family: "M PLUS Rounded 1c";
      letter-spacing: 3px;
    }

    @media (max-width: $breakpoint-max) {
      .cta {
        &::after {
          border-bottom: 7px solid transparent;
          border-left: 12px solid white;
          border-right: 7px solid transparent;
          border-top: 7px solid transparent;
          content: "";
          font-size: 0;
          position: absolute;
          right: 4px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.Homepage-navigation {
  box-shadow: 0px 0px 20px #00000033;
  .container {
    max-width: 1232px;

    .header-wrapper {
      justify-content: left;

      .Desktop-navigation-container {
        .nav-root {
          justify-content: left;
          width: auto;
          float: left;

          li {
            padding: 0 20px;            

            a {
              color: $text-black;
              text-decoration: none;
              font-family: "Noto Sans JP";
              font-weight: 500;
              font-size: 20px;

              @media (max-width: $breakpoint-ipad-max) {
                font-size: 12px;
              }
            }

            .nav-sub {
              &>li {
                padding: 5px;
                margin: 5px 0;
                color: $purple-dark;
                float: left;
                text-align: left;
                width: 100%;

                &>a {
                  color: $purple-dark;
                  text-align: left;
                  float: left;
                  width: 100%;
                  display: block;
                  padding: 0;

                  &::after {
                    color: #8347ad;
                    content: " >";
                    font-size: 16px;
                    float: right;
                    font-weight: 500;
                  }
                }

                &:hover {
                  background: $purple-ultra-light;
                }
              }

              &::before {
                content: "";
                width: 23px;
                height: 17px;
                position: absolute;
                top: -15px;
                left: 0;
                right: 0;
                margin: 0 auto;
                background: url(https://images.ctfassets.net/8g0fievzqg8k/3FwxJ2zAdIngs96PRpDd3L/9ce81f36aa84572bc701702655c086ee/menuArrow.png) no-repeat;
                background-size: 100% 100%;
              }
            }

            &:nth-of-type(2) {
              .nav-sub {
                width: 700px;

                li {
                  width: 50%;
                  float: left;
                  list-style: none;

                  &:hover {
                    background: $purple-ultra-light;
                  }
                }

                ul {
                  width: 100%;
                  float: left;
                  position: relative;
                  padding: 0;
                  border: none;

                  li {
                    width: 49%;
                    float: left;
                    border: none;
                    margin-right: 10px;
                    background: $purple-ultra-light;
                    padding: 0 !important;
                    padding-top: 10px !important;
                    margin-left: -1px !important;

                    &:nth-child(3) {
                      background: none;
                    }

                    .nav-sub {
                      display: block !important;
                      width: 100% !important;
                      float: left;
                      padding: 0 !important;
                      box-shadow: none !important;
                      min-width: 100% !important;
                      z-index: 3 !important;

                      li.wrapper {
                        padding: 0 !important;
                        background: #fff !important;
                        z-index: 0 !important;

                        .heading {
                          padding: 5px !important;
                          font-weight: 500 !important;
                          text-align: left !important;
                          font-size: 14px !important;
                          font-family: "M PLUS Rounded 1c", sans-serif;

                          &::after {
                            float: right;
                            content: ">";
                            font-size: 16px;
                            color: $purple-dark;
                          }

                          &:hover {
                            background: $purple-ultra-light !important;
                          }
                        }

                        .nav-sub-sub {
                          display: none;
                          position: absolute;
                          width: 375px;
                          left: 158%;
                          top: 0;
                          -webkit-transform: translate(-50%, 0);
                          -ms-transform: translate(-50%, 0);
                          transform: translate(-50%, 0);
                          z-index: 999;
                          background: $purple-ultra-light;
                          border-collapse: collapse;
                          box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.4);
                          border: 1px solid $purple-light;
                          padding: 20px 20px 20px 8px;

                          li {
                            width: 100%;

                            background: $purple-ultra-light;
                            padding: 10px !important;

                            &:nth-child(3) {
                              background: none;
                            }

                            .heading {
                              padding: 0 !important;

                              &.disabled {
                                opacity: 0.5;
                              }
                            }

                            a {
                              font-size: 14px;

                              &::after {
                                color: $purple-dark;
                                font-size: 16px;
                                float: right;
                              }
                            }

                            .heading {
                              text-align: left !important;
                              width: 100%;

                              &::after {
                                font-size: 16px;
                                float: right;
                              }
                            }
                          }
                        }

                        &:hover {
                          .nav-sub-sub {
                            display: block;
                          }
                        }
                      }

                      ul.nav-sub-sub {
                        width: 100%;
                        float: left;
                        position: relative;
                        padding: 0;

                        li {
                          width: 100%;
                          float: left;
                          font-weight: normal;
                          text-align: left;

                          .heading {
                            font-weight: 500 !important;
                            text-align: left !important;
                            font-size: 14px !important;
                            font-family: "M PLUS Rounded 1c", sans-serif;
                            width: 100%;

                            &::after {
                              float: right;
                              content: ">";
                              font-size: 16px;
                              color: $purple-dark;
                            }
                          }
                        }
                      }

                      &:before {
                        content: none;
                      }
                    }

                    &:nth-of-type(2) {
                      margin-right: 0 !important;

                      // z-index: 0 !important;
                      .nav-sub {
                        width: 100%;
                        float: left;
                        position: relative;
                        z-index: 0 !important;

                        li {
                          z-index: 0 !important;
                          position: relative !important;
                          width: 100% !important;
                          float: left !important;
                          background: none !important;

                          // .nav-sub-sub {
                          //   display: block !important;
                          //   position: static !important;
                          //   width: 100%;
                          //   background-color: #fff !important;
                          //   border: none;
                          //   float: left;
                          //   left: auto;
                          //   box-shadow: none;
                          //   transform: none !important;
                          //   z-index: 0 !important;
                          //   li {
                          //     background: none !important;
                          //     padding: 0px !important;
                          //     margin: 5px 0 !important;
                          //     .heading {
                          //       padding: 10px !important;
                          //     }
                          //   }
                          // }
                          &:hover {
                            background: $purple-ultra-light !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            &:nth-of-type(3) {
              .nav-sub {

                // width:350px;
                li {
                  float: left;
                  text-align: left;

                  a,
                  .heading {
                    text-align: left;
                    float: left;
                    text-decoration: none;
                    color: $purple-dark !important;
                    width: 100%;

                    &::after {
                      color: #8347ad;
                      content: " >";
                      font-size: 16px;
                      float: right;
                      font-weight: 500;
                    }
                  }
                }
              }
            }

            &:nth-of-type(4),
            &:nth-of-type(1) {
              .nav-sub {
                display: none;

                li.wrapper {
                  float: left;
                  text-align: left;
                  border: none;

                  .heading {
                    padding: 0 !important;
                    font-size: 14px !important;
                    text-align: left !important;
                    width: 100%;
                    font-family: "M PLUS Rounded 1c", sans-serif;
                    font-weight: 500 !important;

                    &::after {
                      float: right;
                      content: ">";
                      font-size: 16px;
                      color: $purple-dark;
                    }
                  }

                  .nav-sub-sub {
                    display: none;
                    left: 143%;
                    top: 15%;
                    width: 250px;
                    margin-top: 10px;
                    min-width: 100%;
                    position: absolute;
                    -webkit-transform: translate(-50%, 0);
                    -ms-transform: translate(-50%, 0);
                    transform: translate(-50%, 0);
                    z-index: 999;
                    background: $purple-ultra-light;
                    border-collapse: collapse;
                    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.4);
                    padding: 20px 10px;
                    border: 1px solid $purple-light;

                    li {
                      background: $purple-ultra-light;
                      border: none;
                      width: 100%;
                      font-size: 14px;
                      text-align: left;
                      width: 100%;

                      a {
                        display: block;
                        text-align: left;

                        &::after {
                          float: right;
                          font-size: 16px;
                          color: $purple-dark;
                        }
                      }

                      &:hover {
                        color: $purple-dark;
                      }
                    }
                  }

                  &:hover {
                    .nav-sub-sub {
                      display: block;
                    }
                  }
                }
              }
            }

            &.product-menu-section {
              height: 45px;
              margin-top: 15px;

              .nav-sub {
                display: none;

                &::before {
                  left: -40%;
                }
              }
            }

            &:nth-of-type(4) {
              .nav-sub {
                li.wrapper {
                  &:nth-of-type(1) {
                    ul.nav-sub-sub {
                      width: 335px;
                    }
                  }

                  &:nth-of-type(2) {
                    ul.nav-sub-sub {
                      top: 42%;
                    }
                  }
                }
              }
            }

            &:nth-of-type(1) {
              .nav-sub {
                li.wrapper {
                  ul.nav-sub-sub {
                    top: 18%;
                    width: 360px;
                    left: 154%;
                  }
                }
              }
            }
          }
        }

        .search-wrapper {
          div {
            .search-input {
              position: relative;
              bottom: 10px;

              input {
                border-radius: 20px;
                padding: 2px 10px;
                border: 1px solid #ccc;
                box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.4);
                width: 180px;
              }

              .search-icon {
                position: absolute;
                right: 0;
                top: 35%;
              }
            }
          }
        }
      }

      .valueSectionText {
        color: $purple;
        width: 100%;
        margin: 0 auto 68px;
        float: left;
        font-size: 30px;
        line-height: 40px;
        text-align: center;

        @media (max-width: $breakpoint-max) {
          font-size: 24px;
          line-height: 35px;
          margin: 0 auto 58px;
          padding: 0 15px;
        }
      }

      .category2 {
        .gold-title {
          color: $gold !important;
          font-size: 28px !important;
        }
      }

      .retailer-container {
        width: 100%;

        .pop-btn {
          position: absolute;
          top: 28px;
          right: 20px;
        }

        .retailer-popup-backdrop {
          background: rgb(255 255 255 / 74%);
          top: 0;
          position: absolute;
          width: 100%;
          height: 50vw;
          @include display-flex-center;

          .retailer-popup {
            max-width: 776px;
            height: 400px;
            display: flex;
            flex-direction: column;
            background-color: #F1EBF6;
            box-shadow: 3px 3px 3px #2F105766;
            border: none;
            width: 100%;

            .retail-btn-wrapper {
              display: flex;
              justify-content: center;
              width: 100%;
              align-items: center;
              height: 100%;

              .image-btn {
                max-width: 236px;
                padding: 0 20px;

                img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.hm-animate {
  transition: opacity 2s ease-out;
  will-change: opacity;
  opacity: 1;

  @media (max-width: $breakpoint-max) {
    opacity: 1;
    transition: none;
  }
}

.hm-hide {
  opacity: 0;
  transition: opacity 2s ease-out;
  will-change: opacity;

  @media (max-width: $breakpoint-max) {
    opacity: 1;
    transition: none;
  }
}

.home-retailer-dropdown {
  a {
    div {
      margin: 20px 0 !important;
    }
  }
}

.home-dropdown {
  .dropdown-content {
    padding: 0 1rem;
  }
}

.popup-container+.cardPromo {
  .title {
    color: #ccc !important;
    font-family: "Caveat-Regular" !important;
    font-size: 48px !important;
    margin-bottom: 30px !important;

    &::after {
      background: none !important;
    }
  }
}

.banner-carousel {
  .slick-slide {
    div {
      width: 100%;

      img {
        height: 100%;
      }

      cursor: pointer;
    }
  }

  .slick-arrow {
    width: 50px;
    position: absolute;
    margin-top: 16%;
    z-index: 9;
    font-size: 0;
    height: 50px;
    left: 3%;
    border: none;
    background: $purple-dark;
    cursor: pointer;

    &:before {
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff;
      content: "";
      font-size: 0;
      height: 25px;
      left: 18%;
      position: absolute;
      top: 25%;
      transform: rotate(45deg) scale(0.5);
      width: 25px;
    }
  }

  .slick-next {
    right: 3%;
    left: auto;
    margin-top: 16%;
    position: absolute;
    top: 0;

    &:before {
      transform: rotate(225deg) scale(0.5);
    }
  }
}

@media (max-width: $breakpoint-max) {
  .popup-container+.cardPromo {
    margin-top: 70px;
  }

  .banner-carousel {
    .slick-slide {
      div {
        height: auto;

        img {
          height: auto;
        }
      }
    }
  }
}

.mobile-menu {
  .floating-menu {
    .outer {
      .title-test {
        width: 100%;
        color: #8347ad;
        padding: 10px 20px;
        background-color: rgba(193, 163, 214, 0.25);
        display: block;
        font-weight: 500;
        border: 1px solid #ccc;
      }
    }

    .retailer-popup-backdrop {
      @media (max-width: $breakpoint-ipad-max) {
        background-color: #fff;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        @include display-flex-center;
        text-align: center;
      }

      .retailer-popup {
        width: 200px;
        background-color: $light-purple-bg;
        box-shadow: 3px 3px 5px 0px rgba(47,16,87,0.4);
      }

      .image-btn img {
        @media (max-width: $breakpoint-ipad-max) {
          width: 200px;
          display: block;
          margin: auto;
        }

        @media (max-width: $breakpoint-tb-min) {
          width: 100px; 
          display: block;
          margin: 30px auto;
        }
      }

      .header-popup-btn-sp {
        background-color: #fff;
        border: 1px solid $purple-darker;
        border-radius: 20px;
        width: 110px;
        margin: 0 auto 50px;
        color: $purple-darker;
        font-size: 16px; 
        font-family: "Noto Sans JP";
        font-weight: 500;

        .modal-popup-close-btn-sp {
          display: inline-block;
          float: left;
          padding-left: 8px;
        }
      }
    }
  }
}

.imageSection-wrapper-dt {
  .imageSection-wrapper-dt-left {
    .imageSection-text {
      .philosophyHeading {
        font-size: 30px;
        line-height: 44px;
      }

      .philosophySubHeading {
        font-size: 24px;
        line-height: 40px;
      }

      .philosophyText {
        font-size: 22px;
        line-height: 38px;
      }
    }
  }
}

.homepage-lt {
  color: #8347ad;
  width: 100%;
  margin: 0 auto 68px;
  float: left;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  font-family: Noto Serif JP;
  font-weight: normal;

  @media (max-width: $breakpoint-max) {
    font-size: 24px;
    line-height: 35px;
    margin: 0 auto 58px;
    padding: 0 15px;
  }
}

.productSection {
  width: 1000px;
  margin: 0 auto;
  position: relative;

  .category-text {
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    color: $text-article;
    font-family: "Noto Sans JP";
    font-weight: 500;
    width: 100%;
    float: left;
    margin: 70px 0 70px 0;
  }

  .grey-text {
    color: $off-black;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    font-family: "Noto Serif JP";
    font-size: 21px;
    line-height: 28px;
    margin: 0 0 50px 0;
    float: left;

    @media (max-width: $breakpoint-max) {
      font-size: 14px;
      line-height: 28px;
      margin: 0 0 25px 0;

      p {
        margin-bottom: 0;
      }
    }
  }

  .purple-text {
    color: $purple;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    font-size: 30px;
    line-height: 35px;
    font-family: "Noto Serif JP";
    margin: 0 0 80px 0;

    @media (max-width: $breakpoint-max) {
      margin: 0 0 40px 0;
      font-size: 22px;
      line-height: 28px;
    }
  }

  .productsLineUp {
    float: left;
    position: relative;
    display: flex;
    text-align: center;
    justify-content: center;

    .productsLineUp-card {
      width: 192px;
      height: 100%;
      float: left;
      display: block;
      background: #fff;
      margin: 0 20px 100px 0;
      height: 100%;

      div {
        img {
          width: 144px;
          line-height: 0;
          text-align: center;
          margin: 10px auto;
        }
      }

      h4 {
        background: #fff;
        // color: $gold;
        font-size: 20px;
        padding: 0 0 10px 0;
        width: 100%;
        float: left;
        margin: 0 auto;
        text-align: center;
        font-family: "Noto Sans JP";
      }

      img {
        width: 50%;
      }

      .bin-button {
        margin: 20px 0 0 0;
        float: left;

        img {
          width: auto;
        }
      }
    }
  }

  @media (max-width: $breakpoint-max) {
    width: 100%;
    float: left;
    margin: 0 auto;

    .category-text {
      font-size: 24px;
      line-height: 28px;
      margin: 0 0 40px 0;
    }

    .productsLineUp {
      display: block;
      padding: 0 20px;

      .productsLineUp-card {
        width: 43%;
        float: left;
        margin: 0 6px 47px 0;

        &:nth-child(even) {
          margin: 0 0 50px 0;
          float: right;
        }

        div {
          img {
            width: 100%;
            line-height: 0;
            float: left;
            height: 100%;
          }
        }

        h4 {
          background: #fff;
          color: $gold;
          font-size: 20px;
          padding: 0 0 10px 0;
        }

        img {
          width: 159px;
          height: 60px;
        }

        .bin-button {
          margin: 0;

          .buy-now-button {
            margin: 0;

            .ps-button-label {
              font-size: 12px;
            }

            img {
              width: auto;
            }
          }
        }
      }
    }
  }
}

.tabcontent {
  float: left;
  width: 100%;
  text-align: left;
  color: $text-article;
  font-size: 28px;
  font-family: "Noto Sans JP";
  font-weight: 400;

  P {
    margin: 0;
  }

  @media (max-width: $breakpoint-max) {
    font-size: 18px;
    line-height: 30px;
    width: 100%;
    float: left;
    text-align: center;
  }
}

.tabcontent-head {
  p {
    float: left;
    width: 100%;
    text-align: left;
    color: $purple;
    margin: 0;
    font-weight: 600;
    font-size: 30px;
    font-family: "Noto Sans JP";
  }

  @media (max-width: $breakpoint-max) {
    border-bottom: 2px solid $purple;
    float: left;
    text-align: center;
    width: 100%;

    p {
      font-size: 28px;
      width: 100%;
      float: left;
      text-align: center;
    }
  }
}

.Lineup-disclaimer {
  color: $text-article;
  font-size: 12px;
  float: left;
  width: 100%;
  text-align: left;
  font-weight: 500;

  p {
    margin: 0;
  }

  @media only screen and (min-width: 320px) and (max-width: 412px) {
    margin-top: 370px !important;
  }

  @media (max-width: $breakpoint-max) {
    position: absolute;
    margin-top: 400px;
    width: 100%;
    font-size: 10px;
    padding: 0 10px;
  }
}

.productContainer {
  .imageset {
    max-width: 850px;
    margin: 0 auto;

    @media (max-width: $breakpoint-max) {
      margin: 0 auto 70px;
    }
  }
}

.id-bind-lohaco .productContainer,
.id-binding-main-page .productContainer {
  background: none !important;
  text-align: center;
  margin: 20px 0 0 0 !important;

  .id-binding-top {
    margin: 0 auto;
    font-family: "M PLUS Rounded 1c", sans-serif;
    color: #ff0000;
    font-size: 30px;
    padding: 10px 0 0 0;
  }

  @media only screen and (max-width: 900px) {
    padding: 20px 20px 0 20px;

    .id-binding-top {
      margin: 30px auto 0;
      font-family: "M PLUS Rounded 1c", sans-serif;
      color: #ff0000;
      font-size: 26px;
      padding: 20px 0 0 0;
    }
  }

  @media only screen and (max-width: 767px) {
    padding: 20px 20px 0 20px;
    margin: 20px 0 0 0 !important;

    .id-binding-top {
      margin: 20px auto 0;
      font-family: "M PLUS Rounded 1c", sans-serif;
      color: #ff0000;
      padding: 20px 0 0 0;

      p {
        margin-bottom: 0;
      }
    }
  }
}

.id-bind-lohaco .productContainer {
  @media only screen and (max-width: 767px) {
    .id-binding-top {
      font-size: 16px;
    }
  }
}

.basicCategorySection {
  float: none;
  margin: 0 auto;
  position: relative;
  width: 1000px;

  @media (max-width: $breakpoint-max) {
    width: 100%;
  }
}

.premiumCategorySection {
  float: none;
  margin: 0 auto;
  position: relative;
  width: 1000px;

  .category-name {
    color: #a38135 !important;
  }

  .variants {
    .cta {
      background: #a38135 !important;
    }
  }

  @media (max-width: $breakpoint-max) {
    width: 100%;
  }
}

.categoryHeading-basic {
  font-size: 26px;
  font-family: "Noto Sans JP";
  color: $purple;
  margin: 0 auto 56px;
  position: relative;
  width: 1000px;
  text-align: left;

  @media (max-width: $breakpoint-max) {
    padding: 0 20px;
    width: 100%;
    float: left;
  }
}

.categoryHeading-premium {
  font-size: 26px;
  font-family: "Noto Sans JP";
  color: #a38135;
  width: 1000px;
  float: none;
  margin: 42px auto 56px;
  clear: both;

  @media (max-width: $breakpoint-max) {
    padding: 0 20px;
    width: 100%;
    float: left;
  }
}

.BasicPage,
.BasicPagePDP,
.BasicPagesPDP,
.AbsorbencyPage,
.PremiumPage,
.AllProducts,
.PhilosophyPage,
.ProductDiagnosisPage {
  .promo-banner {
    margin: 60px auto 140px;
  }
}

.PhilosophyPage {
  .productContainer {
    background: #fff !important;
    margin: 90px 0 150px;

    .productSection {
      img {
        margin: 0 0 80px 0;
      }
    }
  }

  @media (max-width: $breakpoint-max) {
    .productContainer {
      background: #fff !important;
      margin: 45px 0 70px;
      padding: 0 15px;

      .productSection {
        img {
          margin: 0 0 40px 0;
        }
      }
    }
  }
}

.AbsorbencyPage {
  .productContainer {
    padding: 90px 0 150px;

    .title {
      margin: 80px 0 20px 0 !important;
    }
  }

  @media (max-width: $breakpoint-max) {
    padding: 45px 0 70px;

    .title {
      margin: 30px 0 20px 0 !important;
    }
  }
}

.premium-product {
  .productsLineUp-head {
    color: $gold !important;
  }

  h4 {
    color: $gold !important;
  }
}

.ProductDiagnosisPage {
  width: 100%;
  float: left;
  position: relative;
  margin: 0 auto;

  .productDiagnosisSection {
    .grey-text {
      margin: 80px auto 70px;

      p {
        font-size: 30px;
        color: #898c8e;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        font-family: "Noto Sans JP";
        line-height: 40px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .productDiagnosisSection {
      background: #efefef;
      z-index: 0;

      .grey-text {
        margin-top: 13%;

        p {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
  }
}

.prod-main-container {
  .container {
    .questions-row-image {
      img {
        width: 73% !important;
      }
    }

    @media only screen and (max-width: 767px) {
      padding: 0 !important;
      background: #efefef;

      .questions-row-image {
        img {
          width: 100% !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .Newdiagnosispage {
    img {
      margin-top: -97px;
    }

    &::before {
      content: "";
      background: url(/static/images/sp-title.png) no-repeat;
      width: 300px;
      height: 109px;
      display: block;
      position: absolute;
      left: 0;
      top: 8%;
      background-size: contain;
      right: 0;
      margin: 0 auto;
    }
  }
}

.result-banner {
  margin: -110px auto 0;
  width: 100%;
  padding: 0;
  background: #fff;

  @media only screen and (max-width: 767px) {
    width: 100%;
    margin: 0 auto;

    img {
      margin-top: -180px;
    }
  }
}

.result-box {
  width: 970px;
  margin: 0 auto;

  .result-box-container {
    .ps-button-label {
      font-size: 10px;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    padding: 0 15px;
  }
}

.result+div {
  width: 970px;
  margin: 0 auto;

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
}

.Result-product-container {
  width: 100% !important;
  max-width: 100% !important;
  padding: 0;
}

.review-campaign-content-container {
  padding: 50px 20px;
  font: normal normal bold 18px/28px Noto Sans JP;

  @media only screen and (min-width: 1366px) {
    max-width: 1280px;
    margin: 0 auto;
  }

  p {
    font-weight: bold;

    @media only screen and (min-width: 1366px) {
      font: normal normal bold 20px/30px Noto Sans JP;
    }
  }
}

// Akashi plant
.factory-manager,
.Profile-content {
  background-color: #efefef;
}

.small-text-mij {
  font-size: 12px !important;
  line-height: 24px;
}

// Akashi plant 2
.Akashi-Plant2 {
  .content-para {
    div {
      width: 100%;
      float: left;
      position: relative;
      display: block;
    }
  }

  .mij-second-profile {
    .profile-content-section {
      padding: 40px 0 0 0 !important;
    }
  }

  .made-japan-banner {
    margin-top: 40px;
  }

  .ending-section {
    padding: 0px 0 60px !important;

    .extra-line {
      margin: 0 !important;
    }
  }

  // @media only screen and (max-width: 767px){
  //   .mij-second-profile{
  //     .profile-content-section{
  //       padding: 0 20px 30px 0 !important;
  //     }
  //   }
  // }
}

@media only screen and (max-width: 767px) {
  .Akashi-Plant2 {
    .mij-second-profile {
      .profile-content-section {
        padding: 0 20px 25px !important;
      }
    }
  }
}

.second-section-mij,
.mij-second-profile {
  .profile-content-section {
    .Profile-copy-section {
      .content-para {
        span.sub-head {
          width: 10%;
          float: left;
          color: #5a3177;
          font-family: "Noto Sans JP";
          font-weight: normal;
          margin: 10px auto;
        }

        p {
          width: 89%;
          float: left;
          margin: 10px auto;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    span.sub-head {
      width: 100% !important;
      margin: 0 !important;
    }

    p {
      width: 100% !important;
      margin: 0 auto 20px !important;
    }
  }
}

.section-third-mij-banner {
  .content-para {
    span.sub-head {
      width: 35px;
      float: left;
      color: #8347ad;
      font-family: "Noto Sans JP";
      font-weight: normal;
    }

    p {
      width: 93%;
      float: right;
    }
  }

  @media only screen and (max-width: 767px) {
    span.sub-head {
      width: 100% !important;
      float: left;
    }

    p {
      width: 100% !important;
      float: left;
    }
  }
}

/* Camapign Div Tag Implementation*/
#mw-campaign {
  display: none;
}

.img-wrapper {
  margin: 0 auto;
  text-align: center;
}

// homepage popup style

.homepage-popup {
  .ripplemock-popup {}

  .Dialog-content {
    width: 550px;
    top: 4%;
    padding: 0 0;
    background: transparent;
    box-shadow: 0px 0px 22px #2e2e2e;

    img {
      width: 100%;
    }

    border-radius: 0;

    .closeBtn {
      width: 25px;
      height: 25px;
      position: absolute;
      top: 20px;
      right: 20px;
      background-image: url("https://images.ctfassets.net/8g0fievzqg8k/1hu2LoxyJvGwlHHIpRYtmV/28b1f8f3432e32451687e4f6198ee6b6/amazoncouponbanner_x_PCSP.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border: none;
      outline: none;

      .closeBtnSpan {
        display: none;
      }
    }

    .banner-text {
      position: absolute;
      bottom: 0px;
      color: white;
      font-size: 23px;
      font-weight: bold;
      width: 100%;
      text-align: right;
      padding: 0 5% 3% 5%;

      @media only screen and (max-width: 767px) {
        font-size: 16px;
      }

      img {
        width: 7%;
      }

      span {
        margin-right: 10px;
        cursor: pointer;
      }
    }

    .gobutton {
      position: absolute;
      width: 70%;
      height: 70px;
      bottom: 40px;
      left: 50%;
      background: transparent;
      border: none;
      // outline: none;
      transform: translateX(-50%);
      background-image: url("https://images.ctfassets.net/8g0fievzqg8k/7cFVD1i9xRwptNmlOTluQT/f7570823942f24e3a03744ad8d2f24c7/PC_button.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      //sp button ==> https://images.ctfassets.net/8g0fievzqg8k/2axJyVClAg7In6OP037Ekx/bd7753f6327155dabe4a07cefa86cde7/SP_button.png

      //sp img==> 'https://images.ctfassets.net/8g0fievzqg8k/3Mcb0ONgacyyVcB6NLjLrp/3483e53725fe8428bc176bc2ab9cd3d3/SP_img.png'
    }
  }

  @media only screen and (max-width: 767px) {
    .Dialog-content {
      width: 90%;
      top: 50%;
      transform: translateY(-50%);

      .closeBtn {
        width: 17px;
        height: 17px;
        top: 15px;
        right: 15px;
      }

      .gobutton {
        background-image: url("https://images.ctfassets.net/8g0fievzqg8k/2axJyVClAg7In6OP037Ekx/bd7753f6327155dabe4a07cefa86cde7/SP_button.png");
        bottom: 20px;
        height: 58px;
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .banner-text {
    font-size: 16px !important;
  }
}

.lottery-rejection {
  .Dialog-content {
    width: 550px;
    height: 300px;
    top: 25%;
    padding: 0 0;
    background: #f2ecf7;
    box-shadow: 0px 0px 22px #2e2e2e;
    margin: 0 auto;

    .Dialog-error-content {
      width: 100%;
      padding: 40px 20px;
      float: left;
      margin: 0 auto;
      text-align: center;

      .Dialog-error-title {
        font-size: 24px;
        color: $purple-dark;
      }

      .Dialog-error-txt {
        font-size: 18px;
        color: $purple;
      }
    }

    img {
      width: 100%;
    }

    border-radius: 0;

    .closeBtn {
      width: 25px;
      height: 25px;
      position: absolute;
      top: 20px;
      right: 20px;
      background-image: url("https://images.ctfassets.net/8g0fievzqg8k/78WIsXDIusw3Od5EuPa4Az/134a736cb13b0e72d5d6bf594e76405a/icon-close-circle.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border: none;
      outline: none;
    }

    .gobutton {
      position: absolute;
      width: 60%;
      height: 50px;
      bottom: 30px;
      left: 50%;
      background: #592c82;
      border: none;
      color: #fff;
      border-radius: 50px;
      transform: translateX(-50%);
      //sp button ==> https://images.ctfassets.net/8g0fievzqg8k/2axJyVClAg7In6OP037Ekx/bd7753f6327155dabe4a07cefa86cde7/SP_button.png
      //sp img==> 'https://images.ctfassets.net/8g0fievzqg8k/3Mcb0ONgacyyVcB6NLjLrp/3483e53725fe8428bc176bc2ab9cd3d3/SP_img.png'
    }
  }

  @media only screen and (max-width: 767px) {
    .Dialog-content {
      width: 90%;
      top: 15%;

      .Dialog-error-content {
        .Dialog-error-title {
          font-size: 18px;
        }

        .Dialog-error-txt {
          font-size: 14px;
        }
      }

      .closeBtn {
        width: 17px;
        height: 17px;
        top: 15px;
        right: 15px;
      }

      .gobutton {
        bottom: 20px;
        height: 48px;
      }
    }
  }
}

/* Ripple Mock TLE Product */
.ripple-mock-container {
  width: 768px;
  margin: 0 auto;

  @media only screen and (max-width: 767px) {
    width: 100%;
    marign: 0 auto;
  }
}

.homepage-popup .ripple-nofify {
  width: 100%;
  padding: 40px 20px;
  display: flex;
}

.ripplemock-home {
  .Dialog-content {
    width: 300px !important;
    background-color: #fff;
  }
}

.ripplemock-popup {
  .Dialog-content {
    width: 330px !important;
  }
}

/* RippleMock Singin Popup  */
.ripplepopup-singin {
  height: 100%;
  padding: 20px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .form-labell {
    width: 100%;
    float: left;
    margin: 0 auto;
    font-size: 24px;
    color: $purple-dark;
    font-weight: bold;
  }

  .emaillabel {
    width: 100%;
    float: left;
    position: relative;

    input {
      border: 1px solid #ccc;
      width: 100%;
      box-shadow: 0 1px 0 lighten($ig-black, 10%);
      margin: 10px auto;
    }

    .ripple-error {
      width: 100%;
      float: left;
      color: red;
      font-size: 10px;
      margin: 0 auto 5px;

      p {
        margin-bottom: 5px;
      }
    }
  }

  .Ripple-cta {
    width: 100%;
    float: left;
    margin: 0 auto;
  }

  .ripple-tc {
    width: 100%;
    float: left;
    position: relative;
    margin: 0 auto;

    h3 {
      font-size: 16px;
      float: left;
      width: 100%;
      margin: 0 auto 10px;
      font-weight: bold;
      color: $purple-dark;
    }

    p {
      font-size: 10px;
      float: left;
      width: 100%;
      margin: 0 auto;
    }
  }

  .Ripple-cta {
    width: 100%;
    margin: 10px auto;
    float: left;
    position: relative;

    .ripple-submit {
      background: $purple-dark;
      border: none;
      color: #fff;
      padding: 5px 30px;
      border-radius: 20px;
    }
  }
}

.ripple-another {
  text-align: center;

  .notify-txt {
    padding-top: 20px;
  }
}

.ripple-another .notifybtn {
  position: absolute;
  width: 60%;
  height: 60px;
  bottom: 40px;
  left: 50%;
  background: transparent;
  border: none;
  // outline: none;
  transform: translateX(-50%);
  background-image: url("https://images.ctfassets.net/8g0fievzqg8k/5eTltx4npYbiM7SO3TkL41/24a8452b7e1c08dc92e59c4a3acbf5b2/btm_reselect.png") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.Homepage .productSection {
  @media only screen and (min-width: 820px) and (max-width: 1000px) {
    width: 1000px !important;
  }
}

.Homepage .productSection .products-wrapper {
  @media only screen and (min-width: 820px) and (max-width: 1000px) {
    width: auto !important;
  }
}

.BasicPage .productSection,
.BasicPagePDP .productSection {
  display: flex;
  flex-direction: column;
}

.BasicPagePDP .tab-items .text-right .text-right-wrapper {
  bottom: unset !important;
  top: 80px;
}

.BasicPagePDP .tabSection {
  margin: 50px 0 150px 0;

  @media only screen and (max-width: 1180px) {
    margin: 0;
  }
}

.BasicPagePDP .productContainer {
  @media only screen and (max-width: 1180px) {
    margin-bottom: 0;
  }
}

.BasicPagesPDP .buy-now-cta .ps-button-label {
  line-height: 50px;
}

.BasicPagesPDP,
.productSection,
.BasicPagePDP .productSection {
  display: flex;
  flex-direction: column;
}

.BasicPagesPDP .tab-items .text-right .text-right-wrapper {
  bottom: unset !important;
  top: 80px;
}

.BasicPagesPDP .tabSection {
  margin: 50px 0 150px 0;

  @media only screen and (max-width: 768px) {
    margin: 70px 0 0 0px;
  }
}

.BasicPagesPDP {
  @media only screen and (min-width: 769px) and (max-width: 1098px) {
    width: fit-content;

    .productContainer {
      @media only screen and (max-width: 1180px) {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1500px) {
  .BasicPagesPDP {
    .static-header-banner {
      .c-picture {
        img {
          display: block;
          height: 663px;
        }
      }
    }
  }
}

.Newdiagnosispage .static-header {
  @media (max-width: $breakpoint-max) {
    margin-top: -200px !important;
  }

  @media (min-width: 768px) and (max-width: 789px) {
    margin-top: -430px !important;
  }
}

@media (min-width: 768px) and (max-width: 789px) {
  .Newdiagnosispage {
    &::before {
      content: "";
      background: url(/static/images/sp-title.png) no-repeat;
      width: 637px;
      height: -webkit-fill-available;
      display: block;
      position: absolute;
      left: 0;
      top: 8%;
      background-size: contain;
      right: 0;
      margin: 0 auto;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1049px) {
  .prod-main-container .container .questions-row-image img {
    margin-right: 30px;
  }

  .Homepage-navigation .container .header-wrapper .Desktop-navigation-container .nav-root li {
    font-size: 11px !important;
  }

  .Homepage-navigation .container .header-wrapper .retailer-container .retailers-dropdown .dropdown {
    width: auto;
  }

  .Homepage-navigation .container .header-wrapper .Desktop-navigation-container {
    padding: 0 1% !important;
  }
}
.ps-button-label, small{
  display: none !important;
}`;

export default styles;